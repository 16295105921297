<template>
  <v-app>
    <div>
      <v-data-table
        :headers="headers"
        :items="this.allUsers"
        :search="search"
        sort-by="id"
        class="elevation-1"
        :items-per-page="25"
        :rows-per-page-items="this.perPage"
        :server-items-length="this.totalUsers"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title
              >Lista cu toti utilizatorii aplicatiei</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Cauta"
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nume"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.phone"
                          label="Telefon"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
<!--                      <v-col cols="12" sm="6" md="4">-->
<!--                        <v-text-field-->
<!--                          v-model="editedItem.isSuperAdmin"-->
<!--                          label="Drepturi Admin"-->
<!--                        ></v-text-field>-->
<!--                      </v-col>-->
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.status"
                          label="Status"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Anuleaza
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Salveaza
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAdmin" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Doriti sa marcati contul ca Administrator?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Nu</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="makeItemAdminConfirm"
                    >Da</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="makeItemAdmin(item)">
            mdi-account-check-outline
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<style lang="scss">
.v-data-footer__select {
  opacity: 0 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_USERS } from "@/core/services/store/profile.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ADMIN_UPDATE_PERSONAL_INFO } from "../../../core/services/store/profile.module";
import { ADMIN_MAKE_USER_ADMIN } from "../../../core/services/store/profile.module";
import Swal from "sweetalert2";

export default {
  data: () => ({
    dialog: false,
    dialogAdmin: false,
    search: "",
    headers: [
      { text: "ID", value: "id" },
      {
        text: "Nume",
        align: "left",
        sortable: true,
        value: "name"
      },
      { text: "Telefon", value: "phone" },
      { text: "Email", value: "email" },
      { text: "Drepturi admin", value: "super_admin" },
      { text: "Status", value: "status" },
      { text: "Actiuni", value: "actions", sortable: false }
    ],
    users: [],
    totalUsers: 0,
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 4,
      sortBy: 'id',
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8, 16]
    },
    perPage: 25,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Vezi toti utilizatorii" }]);
    this.$store.dispatch(GET_ALL_USERS).then( response => {
      //console.log('dispatch: ', response);
      this.totalUsers = response.meta.pagination.total;
      // this.perPage = response.meta.pagination.per_page;
    });
  },
  computed: {
    ...mapGetters(["allUsers"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Editeaza User";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogAdmin(val) {
      val || this.closeDelete();
    }
  },

  created() {
    // this.initialize();
  },

  methods: {
    initialize() {
      Object.entries(this.allUsers).forEach(item => {
        if (item[1].status === 1) {
          item[1].status = "Activ";
        } else {
          item[1].status = "Inactiv";
        }

        if (item[1].super_admin === true) {
          item[1].super_admin = "Da";
        } else {
          item[1].super_admin = "Nu";
        }
      });

      this.users = this.allUsers;
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    makeItemAdmin(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogAdmin = true;
    },

    makeItemAdminConfirm() {
      // this.users.splice(this.editedIndex, 1);
      this.$store.dispatch(ADMIN_MAKE_USER_ADMIN, [{ id: this.editedItem.id }]).then(()=>{
        Swal.fire({
          title: "",
          text: "Succes!",
          icon: "success",
          confirmButtonClass: "btn btn-success"
        });
      })
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogAdmin = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.users.push(this.editedItem);
      }

      var payload = this.editedItem;
      delete payload.avatar;
      delete payload.birthday;
      delete payload.isSuperAdmin;
      delete payload.super_admin;

      if (payload.status === "Activ") {
        payload.status = 1;
      } else {
        payload.status = 0;
      }

      this.$store.dispatch(ADMIN_UPDATE_PERSONAL_INFO, payload).then(() => {
        Swal.fire({
          title: "",
          text: "Succes!",
          icon: "success",
          confirmButtonClass: "btn btn-success"
        });
      })
      this.close();
    }
  }
};
</script>
